/* dependencies */
import { menu } from "../types";

/* menus */
const menus: menu[] = [

    // dashboard menu
    {
        title: "dashboard",
        link: "/dashboard",
        icon: "dashboard",
        visible: true
    },

    // vendor menu
    {
        title: "Vendor",
        link: "#",
        icon: "verified",
        visible: true,
        hasSubMenu: true,
        subMenu: [
            {
                title: "request vendor",
                link: "/vendor/form",
                visible: true
            },
            {
                title: "list vendors",
                link: "/vendor/list",
                visible: true
            }
        ]
    },

    // contact menu
    {
        title: "Contact",
        link: "#",
        icon: "phone_iphone",
        visible: true,
        hasSubMenu: true,
        subMenu: [
            {
                title: "new contact",
                link: "/contact/form",
                visible: true
            },
            {
                title: "list contacts",
                link: "/contact/list",
                visible: true
            }
        ]
    },

    // group menu
    {
        title: "group",
        link: "#",
        icon: "group",
        visible: true,
        hasSubMenu: true,
        subMenu: [
            {
                title: "new group",
                link: "/group/form",
                visible: true
            },
            {
                title: "list groups",
                link: "/group/list",
                visible: true
            }
        ]
    },


    // message menu
    {
        title: "message",
        link: "#",
        icon: "sms",
        visible: true,
        hasSubMenu: true,
        subMenu: [
            {
                title: "new message",
                link: "/message/form",
                visible: true
            },
            {
                title: "list messages",
                link: "/message/list",
                visible: true
            }
        ]
    },

    // developer menu
    {
        title: "developer",
        link: "/developer",
        icon: "developer_mode",
        visible: true,
        hasSubMenu: false,
    },

    // payment menu
    {
        title: "payment",
        link: "/payment/list",
        icon: "paid",
        visible: true,
        hasSubMenu: false,
    },

    // referer menu
    {
        title: "earning",
        link: "/earning",
        icon: "money",
        visible: true,
        hasSubMenu: false,
    },

    // user menu
    {
        title: "user",
        link: "#",
        icon: "groups",
        visible: true,
        hasSubMenu: true,
        subMenu: [
            {
                title: "new user",
                link: "/user/form",
                visible: true
            },
            {
                title: "list users",
                link: "/user/list",
                visible: true
            }
        ]
    },

    {
        title: "profile",
        link: "#",
        icon: "settings",
        visible: true,
        hasSubMenu: true,
        subMenu: [
            {
                title: "view profile",
                link: "/profile/view",
                visible: true
            },
            {
                title: "edit profile",
                link: "/profile/edit",
                visible: true
            },
            {
                title: "change password",
                link: "/profile/password",
                visible: true
            }
        ]
    },

]

/* exporting menus */
export default menus