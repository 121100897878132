// dependencies
import moment from "moment"
import { string } from "fast-web-kit"

// server port
const port: number = 1000

// domain name
export const domain = "https://emessage.co.tz"

/* creating and exporting backend server URL */
export const serverURL: string = process.env.NODE_ENV === "development" ? `http://localhost:${port}` : `${domain}`

// socket url
export const socketURL: string = process.env.NODE_ENV === "development" ? `http://localhost:${port}` : `${domain}:${port}`


/* creating and exporting application name */
export const applicationName: string = "EMessage"

/* creating and exporting text object formating */
export const text = {
    format: (text: string): string => string.toSnakeCase(text),
    reFormat: (text: string): string => string.toTitleCase(string.removeCase(text, "snake_case")),
    abbreviate: (text: string): string => (string.getAbbreviation(string.removeCase(text, "snake_case")))
}

/* base 64 object for encryption and decryption */
export const encryption = {
    encrypt: (data: any) => window.btoa(JSON.stringify(data)),
    decrypt: (data: any) => data ? JSON.parse(window.atob(data)) : null
}

/* creating and exporting storage object for storing and retrieving data from browser local storage */
export const storage = {
    clear: () => localStorage.clear(),
    remove: (key: string): void => localStorage.removeItem(encryption.encrypt(key.trim())),
    retrieve: (key: string) => encryption.decrypt(localStorage.getItem(encryption.encrypt(key.trim()))),
    store: (key: string, data: any): void => localStorage.setItem(encryption.encrypt(key.trim()), encryption.encrypt(data)),
}

/* creating and exporting  function for getting specific local storage data information */
export const getInfo = (key: string, info?: string) => {

    // retrieving data from local storage
    const data = storage.retrieve(key)

    // verifying data is available
    if (data)

        // verifying data information has been provided
        if (info?.trim())
            // returning requsted info
            return data[info]

        // data info is not provided
        else
            // returning all data information NOTE: same as storage.retrieve() function
            return data

    else
        return null

}

/* admin */
export const isAdmin: boolean = (getInfo("user", "createdBy") === null) && (getInfo("user", "accountType") === "_a_d_m_i_n_") ? true : false

/* backend api path */
export const api: "api/" = "api/"
export const apiV1: "api/v1/" = "api/v1/"

/* common listing condition */
const condition = { deleted: false, accountType: { $ne: "_a_d_m_i_n_" } }
export const user = getInfo("user", "_id")
export const commonCondition: object = isAdmin ? { ...condition } : { ...condition, user }

/* date formating */
export const formatDate = (date: any): string => new Date(date).toDateString()

/* user account types */
export const accountTypes: string[] = ["agent"]

export const pageNotfound: string = "/page-not-found"

export const userViewLists: { name: string, icon: string }[] = [
    { name: "message", icon: "mail" },
    { name: "vendor", icon: "verified" },
    { name: "group", icon: "group" },
    { name: "contact", icon: "phone_iphone" },
    { name: "payment", icon: "paid" },
    { name: "user", icon: "groups" },
]

/* payment types */
export const paymentTypes: string[] = [
    "agent", "vendor_name_registration", "vendor_message"
]

/* payment status */
export const paymentStatus: string[] = [
    "incoming", "outgoing"
]

export const isDemo: boolean = getInfo("user", "userName") === "demo_account"


// removing emojis
export const removeEmoji = (text: string): string => text ? text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, "") : ""

export const getRelativeTime = (date: any) => moment(date).startOf("seconds").fromNow()

export const newHelper: string = ""
